import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';

const setAuthorizationLink = setContext(async (_request, prevContext) => {
  const headers = prevContext.headers;

  if (typeof window === 'undefined') {
    return {};
  }

  let token = Cookies.get('token');

  if (!token) {
    const tokenUrl = process.env.TOKEN_URL as string;

    const tokenResponse = await fetch(tokenUrl, {
      method: 'POST',
      headers: {
        'X-CORE-SITE': process.env.SITE as string,
      },
    });

    const responseBody = await tokenResponse.json();
    token = responseBody.token;

    Cookies.set('token', token, {
      expires: 7,
      path: '/',
    });
  }

  return {
    headers: token
      ? {
          Authorization: 'Bearer ' + token,
          'X-CORE-CREDENTIAL': token,
          ...headers,
        }
      : headers,
  };
});

export default setAuthorizationLink;
