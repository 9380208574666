import { ApolloClient } from '@apollo/client';
import 'isomorphic-unfetch';
import { InitApolloClient, InitApolloOptions } from './types/definitions';

let _client;

export default function initApollo<T = any>(
  clientFn: InitApolloClient<T>,
  options?: InitApolloOptions<T>
): ApolloClient<T> {
  if (typeof window === 'undefined') {
    return getClient<T>(clientFn, options);
  }

  if (!_client) {
    _client = getClient<T>(clientFn, options);
  }

  return _client;
}

function getClient<T>(
  clientFn: InitApolloClient<T>,
  options: InitApolloOptions<T> = {}
) {
  if (typeof clientFn !== 'function') {
    throw new Error('ApolloClient is not a function');
  }

  return clientFn(options);
}

export async function getToken(tokenUrl, site) {
  const json = await fetch(tokenUrl, {
    method: 'post',
    headers: {
      'X-CORE-SITE': site,
    },
  }).then((res) => res.json());

  return json.token;
}
