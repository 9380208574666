import { NextPage } from 'next';
import App from 'next/app';
import React from 'react';
import initApollo from '../apollo';
import {
  ApolloContext,
  InitApolloClient,
  WithApolloOptions,
  WithApolloProps,
  WithApolloState,
} from '../types/definitions';

function getDisplayName(Component: React.ComponentType<any>) {
  return Component.displayName ?? (Component.name || 'Unknown');
}

export default function withApollo<T = any>(
  client: InitApolloClient<T>,
  options: WithApolloOptions = {}
) {
  type ApolloProps = Partial<WithApolloProps<T>>;

  return (
    Page: NextPage<any> | typeof App,
    pageOptions: WithApolloOptions = {}
  ) => {
    const getInitialProps = Page.getInitialProps;
    const getDataFromTree =
      'getDataFromTree' in pageOptions
        ? pageOptions.getDataFromTree
        : options.getDataFromTree;
    const render = pageOptions.render ?? options.render;

    function WithApollo({ apollo, apolloState, ...props }: ApolloProps) {
      const apolloClient =
        apollo ?? initApollo<T>(client, { initialState: apolloState?.data });

      if (render) {
        return render({
          Page: Page as NextPage<any>,
          props: { ...props, apollo: apolloClient },
        });
      }

      return <Page {...props} apollo={apolloClient} />;
    }

    WithApollo.displayName = `WithApollo(${getDisplayName(Page)})`;

    if (getInitialProps || getDataFromTree) {
      WithApollo.getInitialProps = async (pageCtx: ApolloContext) => {
        const ctx = 'Component' in pageCtx ? pageCtx.ctx : pageCtx;
        const { AppTree } = pageCtx;
        const headers = ctx.req ? ctx.req.headers : {};
        const apollo = initApollo<T>(client, { ctx, headers });
        const apolloState: WithApolloState<T> = {};

        let pageProps = {};

        if (getInitialProps) {
          ctx.apolloClient = apollo;
          pageProps = await getInitialProps(pageCtx as any);
        }

        if (typeof window === 'undefined') {
          if (ctx.res && (ctx.res.headersSent || ctx.res.finished)) {
            return pageProps;
          }

          if (getDataFromTree) {
            try {
              const props = { ...pageProps, apolloState, apollo };
              const appTreeProps =
                'Component' in pageCtx ? props : { pageProps: props };

              await getDataFromTree(<AppTree {...appTreeProps} />);
            } catch (error) {
              if (process.env.NODE_ENV !== 'production') {
                console.error('GraphQL error occurred', error);
              }
            }

            apolloState.data = apollo.cache.extract();
          }
        }

        (apollo as any).toJSON = () => {
          return null;
        };

        return {
          ...pageProps,
          apolloState,
          apollo,
        };
      };
    }

    return WithApollo;
  };
}
