import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import withApollo from '../lib/apollo/hoc/withApollo';
import setAuthorizationLink from '../lib/apollo/set-authorization-link';
import { Router } from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import '../styles/global.css';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const VolvoApp = ({ Component, pageProps, apollo }) => (
  <>
    <DefaultSeo
      defaultTitle="Volvo Car Bookstore"
      titleTemplate="%s | Volvo Car Bookstore"
    />
    <Script
      strategy="afterInteractive"
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GTAG}`}
    />
    <Script
      id="analytics-init"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.GTAG}');
        `,
      }}
    />
    <Script
      strategy="beforeInteractive"
      src="https://kit.fontawesome.com/cbfdf353f0.js"
      crossOrigin="anonymous"
    />
    <ApolloProvider client={apollo}>
      <Component {...pageProps} />
    </ApolloProvider>
  </>
);

export default withApollo(({ initialState }) => {
  return new ApolloClient({
    link: ApolloLink.from([
      setAuthorizationLink,
      new HttpLink({
        uri: process.env.API_URL + '/graphql',
        credentials: 'omit',
        headers: {
          'X-CORE-SITE': process.env.SITE!,
        },
      }),
    ]),
    cache: new InMemoryCache().restore(initialState || {}),
  });
})(VolvoApp);
